import React from 'react';
const Documentos = ((docs:any): JSX.Element=>{
    console.log(docs)
    const documentos = docs.docs
    return(
        <>
            <div className='documentos'>
              <div className="wrap-documentos">
                <div className="header-documentos">
                  <h2>Documentos</h2>
                </div>
                <div className="items">
                  {
                    documentos && documentos.map((node:any)=>{
                      return (
                        <div className="item">
                          <div className="wrap-item">
                            <div className="header-item">
                                <div className="cat">
                                    <p>{node.category}</p>
                                </div>
                                <div className="title">
                                    <h2>{node.title}</h2>
                                </div>
                            </div>

                            <div className="footer">
                                <div className="date-autor">
                                    <p>{node.date}</p>
                                </div>
                                <div className="link">
                                    <a href={node.slug}>Previsualizar</a>
                                </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
        </>
    )
})
export default Documentos;
