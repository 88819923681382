import React from 'react';


const FeaturedDoc = ((post:any): JSX.Element=>{
    const flash = post.post
    return(
        <div className='featured-doc'>
            <div className="featured-doc-wrap">
                <div className="cat">
                <p>DOCUMENTO DESTACADO</p>
                </div>
                <div className="title">
                <a href={flash.slug}>{flash.title}</a>
                </div>
                {/* <div className="resume">
                    <p>{flash.description}</p>
                </div> */}
                <div className="footer">
                <div className="date-autor">
                    <p>{flash.date}. {flash.author}.</p>
                </div>
                <div className="link">
                    <a href={flash.slug}>Ver más</a>
                    </div>
                </div>

            </div>
        </div>
    )
})
export default FeaturedDoc;