import * as React from 'react';
import { useState } from 'react';
// import CategoryLayout from '../components/CategoryLayout';
import FeaturedFlash from '../components/layout/FeaturedFlash';
// import OtherPosts from '../components/layout/OtherPosts';
import FlashCards from '../components/FlashCards';

// import LongCards from '../components/LongCards';
// import Table from '../components/Table';
// import PDFPost from '../components/PDFPost';
// import PDFPostsThumbs from '../components/PDFPostsThumbs';

import {
  useFlashEmisoraPosts,
  useCarteraModeloPosts,
  useExpectativasReporteTrimestralPosts,
  useReporteTrimestralPosts,
  useInicioDeCoberturaPosts,
  useExpectativasFinancierasAnualesPosts,
  useOtrosArticulosPosts,
  useTablaDeRecomendacionesRentaVariable,
} from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';

import "../theme/category.scss"
import useIsClient from '../utils/isClient';
import HeaderLayout from '../components/layout/HeaderLayout';
import SearchIcon from '@material-ui/icons/Search';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import FeaturedDoc from '../components/layout/FeaturedDoc';
import Documentos from '../components/layout/Documents';

import RentaBg from '../assets/renta-variable.jpg';
import RecentPosts from '../components/layout/RecentPosts';

import ModalSuscribe from '../components/layout/ModalSuscribre';


export default function RentaVariable(): JSX.Element {
  const flashEmisoraPosts = useFlashEmisoraPosts();
  const carteraModeloPosts = useCarteraModeloPosts();
  const expectativasReporteTrimestralPosts = useExpectativasReporteTrimestralPosts();
  const reporteTrimestralPosts = useReporteTrimestralPosts();
  const inicioDeCoberturaPosts = useInicioDeCoberturaPosts();
  const expectativasFinancierasAnuales = useExpectativasFinancierasAnualesPosts();
  const otherPosts = useOtrosArticulosPosts();
  const tablaDeRecomendaciones = useTablaDeRecomendacionesRentaVariable();
  const color = getCategoryColor('rentaVariable');

  let pdfPosts: any[] = [];

  pdfPosts.push(flashEmisoraPosts[0]);
  pdfPosts.push(expectativasReporteTrimestralPosts[0]);
  pdfPosts.push(expectativasFinancierasAnuales[0]);

  // console.log(pdfPosts)

  var listaDocsSort: any[] = pdfPosts.sort(function (a: any, b: any) {
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  });
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }

  // console.log(listaDocsSort)
  const isClient = useIsClient();
  if(isClient){
    return (
      <>
        <HeaderLayout openModal={openSuscribe}/>

        <div className="header-section-bg">

          <div className='top-bg' style={{ backgroundImage: `url(${RentaBg})` }}></div>

          <div className="container-sec">

            <div className='top-content'>
              <div className='top-title'>
                <h2>Renta Variable</h2>
              </div>
              <div className='top-search'>
                <form action="/buscar">
                  <input type="text" name='q' />
                  <SearchIcon className='icon--search'/>
                </form>
              </div>
            </div>

            <div style={{marginTop: "32px"}}>
              <Documentos docs={listaDocsSort}/>
            </div>


          </div>

        </div>

        <div className="container">


          {/* <div className='header--section'>
            <h2 className='title--section'>Renta Variable</h2>
            <div className='search--section'>
              <form action="/buscar">
                <input type="text" name='q' />
                <SearchIcon className='icon--search'/>
              </form>
            </div>
          </div> */}

          {/* <Documentos docs={listaDocsSort}/> */}

          <FeaturedDoc post={listaDocsSort[0]}/>
          <FeaturedFlash post={flashEmisoraPosts[0]}/>


          <FlashCards header="Flash Emisora" posts={flashEmisoraPosts} />

          <RecentPosts posts={otherPosts} title="Otros artículos" link={true}/>

          {/* <OtherPosts posts={otherPosts}/> */}



        </div>
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
      // <CategoryLayout title="Renta Variable" newHeader={true}>
      //   {/* <PDFPostsThumbs
      //     header="Documentos"
      //     post={expectativasReporteTrimestralPosts[0]}
      //     pdfPosts={listaDocsSort}
      //   />
      //   <PDFPost
      //     color="blue"
      //     header="Documento Destacado"
      //     post={listaDocsSort[0]}
      //   />
      //   <FeaturedFlash
      //     header="Flash Destacado"
      //     post={flashEmisoraPosts[0]}
      //     barColor={color}
      //   /> */}
      //   <FlashCards header="Flash Emisora" posts={flashEmisoraPosts} />
      //   {/* <LongCards header="Cartera Modelo" posts={carteraModeloPosts} /> */}
      //   {/* <Table
      //     header={tablaDeRecomendaciones.tabla.titulo}
      //     src={tablaDeRecomendaciones.tabla.tabla}
      //     height={700}
      //   /> */}
      //   <OtherPosts header="Otros Artículos" posts={otherPosts} />
      // </CategoryLayout>
    );
  }else{
    return(<></>)
  }

}
